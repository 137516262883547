@charset "utf-8";
@import '../../../node_modules/@uppy/core/dist/style.css';
@import '../../../node_modules/@uppy/dashboard/dist/style.css';
@import '../../../node_modules/swiper/modules/autoplay/autoplay.scss';
@import '../../../node_modules/swiper/modules/navigation/navigation.scss';
@import '../../../node_modules/swiper/modules/pagination/pagination.scss';

@font-face {
  font-family: 'GmarketSansMedium';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff')
    format('woff');
  font-weight: 500;
  font-style: normal;
}

$primary: #01a0ff;
$linkareer-body-background: #f8f8fa;
$linkareer-light-blue: #eafaff;
$linkareer-deep-blue: #4990e2;

$link: $primary;
$body-background-color: $linkareer-body-background;

@import url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css);
// $family-primary: 'NanumSquare', sans-serif;

$body-size: 14px;

$input-shadow: none;

$navbar-height: 3rem;

@import '../../../node_modules/bulma/sass/utilities/_all.sass';
@import '../../../node_modules/bulma/sass/base/_all.sass';

@import '../../../node_modules/bulma/sass/grid/_all.sass';

$label-color: $linkareer-deep-blue;
$label-weight: 600;

@import '../../../node_modules/bulma/sass/form/_all.sass';

$footer-background-color: $white;
$footer-padding: 0;

$section-padding: 2rem;

@import '../../../node_modules/bulma/sass/layout/_all.sass';

section {
  @media screen and (max-width: $desktop - 1px) {
    padding: 0px !important;
  }
}

.columns {
  @media screen and (max-width: $desktop - 1px) {
    margin: 0;
  }
}

@import '../../../node_modules/bulma/sass/elements/_all.sass';

.navbar-brand {
  background-color: $primary;
}

a {
  color: $grey-darker;
}

.navbar-item,
.navbar-link {
  padding: 0 !important;
  border-bottom: 3px solid rgba(0, 0, 0, 0%);
  margin-right: 12px;
  &:hover {
    border-bottom: 3px solid $primary;

    a {
      font-weight: 600;
    }
  }
}

$dropdown-content-radius: 0;
$dropdown-content-padding-top: 0;
$dropdown-content-padding-bottom: 0;
$dropdown-item-hover-color: $primary;
$dropdown-item-active-color: $primary;
$dropdown-item-hover-background-color: $linkareer-light-blue;
$dropdown-item-active-background-color: $linkareer-light-blue;

// $panel-item-border: 0;
// $panel-tab-border-bottom: 0;

$panel-heading-background-color: $linkareer-light-blue;
$panel-block-hover-background-color: $linkareer-light-blue;

@import '../../../node_modules/bulma/sass/components/_all.sass';

.is-dimmed {
  background-color: $linkareer-body-background;
}

.panel-tabs {
  a {
    border-bottom: 3px solid rgba(0, 0, 0, 0%);

    &.is-active {
      border-bottom: 3px solid #002f56;
    }

    @media screen and (min-width: $desktop) {
      margin: 0 5%;
    }

    @media screen and (max-width: $desktop - 1px) {
      margin: 0 2%;
    }
  }
}

input[type='text']::-ms-clear {
  width: 0px;
  height: 0px;
  display: none;
}
